import { ThemeConfig } from "../types";
import SectionVerticalDivider from "../components/section-vertical-divider";

const nikeStyleTheme: ThemeConfig = {
    id: "nike-style",
    name: "Nike Style",
    backgroundColor: "#000000",
    textColor: "#ffffff",
    hideGridsWhenPlayingMedia: true,
    gridColor: `rgba(255, 255, 255, .5)`,
    maxMediaWidth: 648,
    gridOffset: undefined,
    gridLineThickness: undefined,
    fullGrid: undefined,
    sectionGrid: SectionVerticalDivider,
    codification: ["heading", "ticker"],
    enableScrim: true,
    showQr: false,
    footer: {
        showIconDividers: true,
    },
    sequence: {
        headlineSwitchDuration: 6_000,
        mediaDuration: 6_000,
        mediaGap: 1_000,
        playMediaInterval: 12_000,
    },
    leftBorder: {
        marginRight: 10,
        thickness: 0,
    },
    style: {
        headline: {
            fontSize: 49,
            marginTop: 5,
            lineHeight: 45,
            letterSpacing: '-0.02em',
            fontFamily: "helvetica-neue-medium",
        },
        eyebrow: {
            fontFamily: 'helvetica-neue',
            fontSize: 14,
            lineHeight: 14.4,
            fontWeight: 500,
            letterSpacing: '-0.02em',
            textTransform: "uppercase",
        },
        qrContent: undefined,
        ticker: {
            fontFamily: 'futura-condensed',
            fontSize: 28,
            lineHeight: 35,
            textTransform: 'uppercase',
            letterSpacing: '-0.02em',
        }
    },
};

export default nikeStyleTheme;